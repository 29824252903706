import type Param from "./server/models/Param";
import FlatParam from "./server/models/FlatParam";
import { Json } from "./api-types";
import type Admin from "./server/models/Admin";

export function flattenParams(
  params: Param[] | undefined,
  values: { [key: string]: string[] | undefined }
) {
  let ret = new Map<string, FlatParam>();
  if (params) {
    for (const param of params) {
      if (param.id) {
        ret.set(
          param.id,
          new FlatParam({
            selected: param.freeForm
              ? values[param.id]?.map((param) => ({ name: param }))
              : param.options?.filter(
                  (option) =>
                    param.id &&
                    option.id &&
                    values[param.id]?.includes(option.id)
                ),
            name: param.name,
            options: param.options,
            required: param.required,
            text: param.text,
            freeForm: param.freeForm,
            freeFormType: param.freeFormType,
          })
        );
      }
      if (param.options) {
        for (const option of param.options) {
          if (
            param.id &&
            option.id &&
            option.params &&
            values[param.id]?.includes(option.id)
          ) {
            ret = new Map([...ret, ...flattenParams(option.params, values)]);
          }
        }
      }
    }
  }
  return ret;
}

export function paramValues(params: { [key: string]: Json<FlatParam> }) {
  return Object.fromEntries(
    Object.entries(params)
      .map(([key, flatParam]) => [
        key,
        flatParam.selected
          ?.map((option) => option.id ?? option.name)
          .filter((id): id is string => !!id),
      ])
      .filter((x): x is [string, string[]] => !!x[1])
  );
}

export function id() {
  return Array.from(crypto.getRandomValues(new Uint32Array(4)), (x) =>
    x.toString(16)
  ).join("");
}

export async function all<T>(iterator: AsyncIterableIterator<T>): Promise<T[]> {
  const ret = [];
  for await (const item of iterator) {
    ret.push(item);
  }
  return ret;
}

export function hasApp(admin: Json<Admin> | undefined, application: string) {
  return (
    admin &&
    (!admin.appWhitelist ||
      admin.appWhitelist.length == 0 ||
      admin.appWhitelist.map((app) => app.id).includes(application))
  );
}

export function hasRole(admin: Json<Admin> | undefined, role: string) {
  return admin?.roles?.includes("Full Access") || admin?.roles?.includes(role);
}
