import "../styles/globals.scss";
import { SessionProvider } from "next-auth/react";
import Head from "next/head";
import Navbar from "../components/Navbar";
import Auth from "../components/Auth";
import { ErrorBoundary } from "@sentry/nextjs";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Container } from "react-bootstrap";
import { AppProps } from "next/app";
import styled from "styled-components";

config.autoAddCss = false;

const MainContainer = styled(Container)`
  min-height: 100vh;
  background: #ffffff;
  padding-top: calc(76px + 5rem);
`;

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Blend360 Intranet</title>
        <link rel="icon" href="/favicon.png" />
      </Head>
      <SessionProvider>
        <ErrorBoundary
          fallback={
            <div className="d-flex align-items-center justify-content-center flex-column flex-grow-1">
              <h2>A fatal error occurred</h2>
              <p className="lead">Try refreshing the page.</p>
            </div>
          }
        >
          <Auth>
            <Navbar />
            <MainContainer>
              <Component {...pageProps} />
            </MainContainer>
          </Auth>
        </ErrorBoundary>
      </SessionProvider>
    </>
  );
}
