import { embed } from "@aws/dynamodb-data-mapper";
import { attribute } from "@aws/dynamodb-data-mapper-annotations";
import OptionData from "./OptionData";
import { Json } from "../../api-types";

class FlatOption {
  @attribute({ type: "String" })
  name?: string;

  @attribute({ type: "String" })
  id?: string;

  @attribute(embed(OptionData))
  data?: OptionData;

  @attribute({ type: "List", memberType: { type: "String" } })
  actions?: string[];

  constructor(option?: Json<FlatOption>) {
    if (option) {
      if (option.name) this.name = option.name;
      if (option.id) this.id = option.id;
      this.data = new OptionData(option.data);
      this.actions = option.actions?.map((x) => x ?? "");
    }
  }
}

class FlatParam {
  @attribute({ type: "String" })
  name?: string;

  @attribute({ type: "List", memberType: embed(FlatOption) })
  selected?: FlatOption[];

  options?: FlatOption[];
  required?: boolean;
  text?: string | null;
  freeForm?: boolean;
  freeFormType?: string;

  constructor(param?: Json<FlatParam>) {
    if (param) {
      if (param.name) this.name = param.name;
      this.options = param.options?.map((option) => new FlatOption(option));
      this.selected = param.selected?.map((option) => new FlatOption(option));
      this.required = param.required;
      if (param.text) this.text = param.text;
      this.freeForm = param.freeForm;
      if (param.freeFormType) this.freeFormType = param.freeFormType;
    }
  }
}

export default FlatParam;
export { FlatOption };
