import Link from "next/link";
import { UrlObject } from "url";
import { ReactNode } from "react";
import styled from "styled-components";

const DropdownMenu = styled.div<{ collapsed: boolean }>`
  min-width: ${({ collapsed }) => (collapsed ? "0" : "130px")};
  background-color: ${({ collapsed }) => (collapsed ? "none" : "#2e2e7b")};
  box-shadow: ${({ collapsed }) =>
    collapsed ? "none" : "2px 2px 5px 0 rgb(0 0 0 / 20%)"};
  display: ${({ collapsed }) => (collapsed ? "block" : "none")};
`;
const Absolute = styled.div<{ collapsed: boolean }>`
  padding-top: ${({ collapsed }) => (collapsed ? "0" : "58px")};
  position: ${({ collapsed }) => (collapsed ? "static" : "absolute")};

  &:hover ${DropdownMenu} {
    display: block;
  }
`;
const DropdownLink = styled.a<{ collapsed: boolean }>`
  color: ${({ collapsed }) => (collapsed ? "#ffffff" : "#323180")};
  cursor: pointer;
  padding: 1rem;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  z-index: 1010;

  &:hover {
    color: ${({ collapsed }) => (collapsed ? "#ffffff" : "#1f95d9")};
  }

  &:hover + ${Absolute} ${DropdownMenu} {
    display: block;
  }
`;

export interface DropdownProps {
  children: ReactNode;
  href: string | UrlObject;
  text: ReactNode;
  collapsed: boolean;
}

export default function Dropdown({
  children,
  href,
  text,
  collapsed,
}: DropdownProps) {
  return (
    <div className="d-flex flex-column">
      <Link href={href} passHref>
        <DropdownLink collapsed={collapsed}>{text}</DropdownLink>
      </Link>
      <Absolute collapsed={collapsed}>
        <DropdownMenu collapsed={collapsed}>{children}</DropdownMenu>
      </Absolute>
    </div>
  );
}
