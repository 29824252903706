import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://4fffffae832544638573336c80b31e9f@o947277.ingest.sentry.io/6002204",
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.name === "/404") {
      return 0.01;
    } else {
      return 0.1;
    }
  },
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
});
