import Link from "next/link";
import { ReactNode } from "react";
import { UrlObject } from "url";
import styled from "styled-components";

const DropdownLinkAnchor = styled.a<{ collapsed: boolean }>`
  display: block;
  padding: ${({ collapsed }) => (collapsed ? "1rem" : "9px 30px")};
  background-color: ${({ collapsed }) => (collapsed ? "none" : "#2e2e7b")};
  color: #fff;
  font-size: ${({ collapsed }) => (collapsed ? "12px" : "10px")};
  font-weight: bold;
  text-align: ${({ collapsed }) => (collapsed ? "left" : "center")};
  text-transform: uppercase;
  text-decoration: none;
  margin-left: ${({ collapsed }) => (collapsed ? "1rem" : "0")};

  &:hover {
    background-color: ${({ collapsed }) => (collapsed ? "none" : "#1f95d9")};
    color: #fff;
  }
`;

export interface DropdownLinkProps {
  children: ReactNode;
  href: string | UrlObject;
  collapsed: boolean;
}

export default function DropdownLink({
  children,
  href,
  collapsed,
}: DropdownLinkProps) {
  return (
    <Link href={href} passHref>
      <DropdownLinkAnchor collapsed={collapsed}>{children}</DropdownLinkAnchor>
    </Link>
  );
}
