import { attribute } from "@aws/dynamodb-data-mapper-annotations";

class DirectoryObject {
  @attribute({ type: "String" })
  name?: string;

  @attribute({ type: "String" })
  email?: string;

  @attribute({ type: "String" })
  id?: string;

  constructor(response?: {
    id?: string | null;
    displayName?: string | null;
    mail?: string | null;
    name?: string | null;
    email?: string | null;
  }) {
    if (response) {
      if ("name" in response && response.name) this.name = response.name;
      if ("displayName" in response && response.displayName)
        this.name = response.displayName;
      if (response.id) this.id = response.id;
      if ("email" in response && response.email) this.email = response.email;
      if ("mail" in response && response.mail) this.email = response.mail;
    }
  }
}

export default DirectoryObject;
