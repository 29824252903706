import { attribute } from "@aws/dynamodb-data-mapper-annotations";
import DirectoryObject from "./DirectoryObject";
import { embed } from "@aws/dynamodb-data-mapper";
import { Json } from "../../api-types";

class OptionData {
  @attribute(embed(DirectoryObject))
  group?: DirectoryObject;

  @attribute(embed(DirectoryObject))
  app?: DirectoryObject | null;

  @attribute({ type: "String" })
  job?: string | null;

  @attribute()
  ou?: string;

  constructor(optionData?: Json<OptionData>) {
    if (optionData) {
      this.group = new DirectoryObject(optionData.group);
      this.ou = optionData.ou;
    }
  }
}

export default OptionData;
