import logo from "../public/blend360-full-color-800x300.svg";
import Image from "next/image";
import Link from "next/link";
import { useSession } from "next-auth/react";
import Dropdown from "./Dropdown";
import DropdownLink from "./DropdownLink";
import { useEffect, useState } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasRole } from "../lib/utils";
import styled from "styled-components";

const StyledNavbar = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: #aaaaaa 0 8px 10px;
  background: #ffffff;
`;
const Toggle = styled(FontAwesomeIcon)`
  color: #2e2e7b;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 24px;
`;
const Inner = styled.div<{ collapsed: boolean }>`
  display: ${({ collapsed }) => (collapsed ? "flex" : "grid")};
  grid: auto / 1fr auto 1fr;
  padding: ${({ collapsed }) => (collapsed ? "0" : "1.5rem")};
  flex-direction: column;
  background: ${({ collapsed }) => (collapsed ? "#2e2e7b" : "none")};
`;
const NavbarLeft = styled.div<{ collapsed: boolean }>`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  align-items: ${({ collapsed }) => (collapsed ? "flex-start" : "center")};
  justify-content: flex-end;
  margin-right: ${({ collapsed }) => (collapsed ? "0" : "1.5rem")};
  flex-direction: ${({ collapsed }) => (collapsed ? "column" : "row")};
`;
const NavbarRight = styled.div<{ collapsed: boolean }>`
  grid-area: 1 / 3 / 2 / 4;
  display: flex;
  align-items: ${({ collapsed }) => (collapsed ? "flex-start" : "center")};
  justify-content: flex-start;
  margin-left: ${({ collapsed }) => (collapsed ? "0" : "1.5rem")};
  flex-direction: ${({ collapsed }) => (collapsed ? "column" : "row")};
`;
const NavbarLogo = styled.a`
  grid-area: 1 / 2 / 2 / 3;
`;
const NavbarLink = styled.a<{ collapsed: boolean }>`
  color: ${({ collapsed }) => (collapsed ? "#ffffff" : "#323180")};
  cursor: pointer;
  padding: 1rem;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: ${({ collapsed }) => (collapsed ? "#ffffff" : "#1f95d9")};
  }
`;

export default function Navbar() {
  const { data: session } = useSession();

  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const listener = () => setCollapsed(window.innerWidth < 720);
    listener();
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  return (
    <StyledNavbar>
      {collapsed && (
        <div className="d-flex p-3">
          <Link href="/">
            <a className="me-auto">
              <Image src={logo} alt="Blend360 Logo" width={150} height={56} />
            </a>
          </Link>
          <Toggle icon={faBars} onClick={() => setOpen((open) => !open)} />
        </div>
      )}
      {(!collapsed || open) && (
        <Inner collapsed={collapsed}>
          <NavbarLeft collapsed={collapsed}>
            <Link href="/onboarding-guide" passHref>
              <NavbarLink collapsed={collapsed}>Onboarding Guide</NavbarLink>
            </Link>
            <Link href="/talent-management" passHref>
              <NavbarLink collapsed={collapsed}>Talent Management</NavbarLink>
            </Link>
          </NavbarLeft>
          {!collapsed && (
            <Link href="/" passHref>
              <NavbarLogo>
                <Image src={logo} alt="Blend360 Logo" width={200} height={75} />
              </NavbarLogo>
            </Link>
          )}
          <NavbarRight collapsed={collapsed}>
            <NavbarLink
              collapsed={collapsed}
              href="https://myapps.microsoft.com"
              target="_blank"
              rel="noreferrer"
            >
              My Apps
            </NavbarLink>
            {session?.admin ? (
              <Dropdown
                href="/self-service"
                text="Access Request"
                collapsed={collapsed}
              >
                <DropdownLink
                  href="/self-service/applications"
                  collapsed={collapsed}
                >
                  Applications
                </DropdownLink>
                {hasRole(session.admin, "Users") && (
                  <DropdownLink
                    href="/self-service/admins"
                    collapsed={collapsed}
                  >
                    Admins
                  </DropdownLink>
                )}
              </Dropdown>
            ) : (
              <Link href="/self-service" passHref>
                <NavbarLink collapsed={collapsed}>Access Request</NavbarLink>
              </Link>
            )}
          </NavbarRight>
        </Inner>
      )}
    </StyledNavbar>
  );
}
